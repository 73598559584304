<template>
  <v-card>
    <v-card-title
      class="align-start flex-nowrap"
      style="word-break: break-word;"
    >
      <span>{{ titleComputed }}</span>

      <v-spacer></v-spacer>

      <v-fab-transition>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="showVerticalDots"
              icon
              x-small
              class="me-n3 mt-n2"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiHelp }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card-title>

    <v-card-text>
      <v-list class="pb-0 match-height vertical-scroll">
        <v-list-item
          v-for="(data,index) in dataComputed"
          :key="index"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4':''}`"
        >
          <v-avatar
            color="secondary"
            size="40"
            :class="`v-avatar-light-bg secondary--text font-weight-medium me-3`"
          >
            <v-img
              v-if="data.facility_logo"
              :src="data.facility_logo"
            ></v-img>
            <span
              v-else
              class="text-base"
            >{{ avatarText(data.facility_name) }}</span>
            <!-- <span class="text-base">{{ avatarText(data.facility_name) }}</span> -->
          </v-avatar>
          <!-- <v-spacer /> -->

          <div class="d-flex align-start flex-grow-1 ">
            <div>
              <div class="font-weight-semibold">
                <span class="text--primary text-body-2 me-1">{{ data.facility_name }}</span>
                <!-- <span class="text--primary text-body-2 me-1">{{ data.facility_users_qty }}</span> -->

                <!-- <v-icon
                  size="20"
                  :color="data.facility_diff.charAt(0) === '+' ? 'success':'error'"
                >
                  {{ data.facility_diff.charAt(0) === '+' ? icons.mdiChevronUp: icons.mdiChevronDown }}
                </v-icon>

                <span :class="`text-xs ${data.facility_diff.charAt(0) === '+' ? 'success--text':'error--text'}`">{{ data.facility_diff.slice(1) }}%</span> -->
              </div>

              <!-- <v-list-item-subtitle class="text-xs">
                {{ data.country }}
              </v-list-item-subtitle> -->
            </div>

            <v-spacer></v-spacer>

            <div>
              <h4 class="font-weight-semibold">
                {{ data.facility_data }}
              </h4>
              <!-- <span class="text-xs">Sales</span> -->
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiHelp, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    showVerticalDots: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'title',
    },
    tooltip: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const salesByCountries = [
      {
        abbr: 'US',
        amount: '$8,656k',
        country: 'United states of america',
        change: '+25.8%',
        sales: '894k',
        color: 'success',
      },
      {
        abbr: 'UK',
        amount: '$2,415k',
        country: 'United kingdom',
        change: '-6.2%',
        sales: '645k',
        color: 'error',
      },
      {
        abbr: 'IN',
        amount: '$865k',
        country: 'India',
        change: '+12.4%',
        sales: '148k',
        color: 'warning',
      },
      {
        abbr: 'JA',
        amount: '$745k',
        country: 'Japan',
        change: '-11.9%',
        sales: '86k',
        color: 'secondary',
      },
      {
        abbr: 'KO',
        amount: '$45k',
        country: 'Korea',
        change: '+16.2%',
        sales: '42k',
        color: 'error',
      },
    ]
    const dataComputed = computed(() => props.items)
    const titleComputed = computed(() => props.title)

    return {
      salesByCountries,

      dataComputed,
      titleComputed,

      avatarText,

      icons: {
        mdiHelp,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style scoped>
  .vertical-scroll {
    height: auto;
    max-height: 280px;
    overflow-y: auto;
    padding-right: 10px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3c3c3c;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
</style>
